<template>
  <div class="content-wrap work-content-wrap">
    <bread-menu title="作品管理">
      <div class="nav-title">
        <router-link to="" tag="span" class="active">作品列表</router-link>
      </div>
    </bread-menu>
    <div class="search">
      <!-- 搜索筛选 -->
      <el-form :inline="true" class="class-search">
        <el-form-item label="作品名" class="search-name">
          <el-input
            size="small"
            placeholder="按作品标题"
            v-model="params.name"
            @input="inputChange"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态" class="search-state">
          <el-select
            placeholder="请选择"
            v-model="options.label"
            @change="changeSelect"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="年级" class="search-grade">
          <el-select v-model="searchForm.grade" placeholder="请选择">
            <el-option label="三年级" value="三年级"></el-option>
            <el-option label="二年级" value="二年级"></el-option>
          </el-select>
        </el-form-item>

        <!-- //日期 -->
        <div class="search-date">
          <span>时间范围</span>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate"
            ref="pickerRef"
          >
          </el-date-picker>
        </div>
        <el-button
          size="medium"
          type="primary"
          icon="el-icon-plus"
          class="add-btn"
          @click="editHandle"
          >添加作品</el-button
        >
      </el-form>
    </div>
    <div class="class-tab work-tab">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="number" label="序号">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="coverUrl" label="视频封面">
          <template slot-scope="scope">
            <img :src="scope.row.coverUrl" min-width="100" height="50" />
          </template>
        </el-table-column>
        <el-table-column prop="description" label="作品标题"> </el-table-column>
        <el-table-column prop="difficulty" label="难度">
          <template slot-scope="scope">
            {{ scope.row.difficulty | formatDifficulty }}
          </template>
        </el-table-column>
        <el-table-column prop="gradeName" label="年级"> </el-table-column>
        <el-table-column prop="sectionName" sortable label="章节">
        </el-table-column>
        <el-table-column prop="createTime" label="发布时间" width="200">
          <template slot-scope="scope">
            {{ (scope.row.createTime * 1000) | formatDate }}
          </template>
        </el-table-column>
        <el-table-column prop="verifyState" label="当前状态">
          <template slot-scope="scope">
            <div
              class="verifyState"
              :class="{ isfinish: scope.row.verifyState == 1 }"
            >
              {{ scope.row.verifyState | formatVerifyState }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="likeNum" label="点赞数"> </el-table-column>
        <el-table-column prop="forwardNum" label="转发数"> </el-table-column>
        <el-table-column label="操作" width="136">
          <template slot-scope="scope">
            <el-button type="primary" class="editBtn" @click="editHandle"
              >编辑作品</el-button
            >
            <el-button
              type="primary"
              class="deleteBtn"
              @click="removeUserById(scope.row.id)"
              >下架</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="total"
        v-if="tableData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import breadMenu from "@/components/breadMenu.vue";
import { formatDate } from "@/utils/date.js";
export default {
  name: "work",
  components: {
    breadMenu,
  },
  data() {
    return {
      value1: "",
      searchForm: {
        examine: "",
      },
      tableData: [],
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核未通过",
        },
      ],
      page: {
        total: 0, // 总页数
        current: 1, // 当前页数
        size: 10, // 每页显示多少条
      },
      params: {
        workUserId: "",
        token: "",
        gradeId: "",
        name: "",
        verifyState: "",
        userId: "",
        workId: "",
      },
      total: 0,
    };
  },
  created() {
    this.params.token = window.localStorage.getItem("token");
    this.params.workUserId = window.localStorage.getItem("userId");
    this.params.userId = window.localStorage.getItem("userId");
    this.getData();
  },
  filters: {
    formatVerifyState(num) {
      if (num == 0) {
        return "待审核";
      } else if (num == 1) {
        return "审核通过";
      } else {
        ("审核未通过");
      }
    },
    formatDifficulty(num) {
      if (num == 1) {
        return "简单";
      } else if (num == 2) {
        return "中等";
      } else {
        ("困难");
      }
    },
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm");
    },
  },
  methods: {
    getData(page) {
      this.$axios({
        method: "post",
        params: Object.assign(
          {
            current: page == null ? this.page.current : page.currentPage,
            size: page == null ? this.page.size : page.pageSize,
          },
          this.params
        ),
        url: `/teacher/work/myWorks`,
      }).then((res) => {
        console.log(res);
        if (res.data.code !== "0000") {
          return this.$message.error(res.data.message);
        }
        this.tableData = res.data.data.works.records;
        this.total = res.data.data.works.total;
      });
    },
    editHandle() {
      this.$alert("功能正在开发中......", "", {
        confirmButtonText: "确定",
        callback: (action) => {},
      });
    },

    //删除操作
    async removeUserById(id) {
      this.params.workId = id;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该作品, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$axios({
        method: "post",
        params: this.params,
        url: `/teacher/work/delete`,
      });
      if (res.data.code == "0000") {
        this.getData();
        return this.$message.info("删除成功");
      }
    },

    //分页
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      // console.log(newSize)
      this.page.size = newSize;
      this.getData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.page.current = newSize;
      this.getData();
    },
    inputChange() {
      this.getData();
    },
    changeSelect(val) {
      this.params.verifyState = val;
      this.getData();
    },
    changeDate() {
      var val = this.$refs.pickerRef.displayValue;
      var startTime = val[0];
      var endTime = val[1];
      this.params.startTime = startTime;
      this.params.endTime = endTime;
      this.getData();
    },
  },
};
</script>

<style lang="scss">
.editBtn,
.deleteBtn {
  background: none;
  border: none;
  width: 42px;
  color: #3d7fff;
  padding: 0;
  &:hover {
    background: none;
    border: none;
    color: #3d7fff;
  }
}
</style>